<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="안전작업표준 작업명" :value="param.sopName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="단위공정" :value="param.processName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="안전작업표준 관리번호" :value="param.sopNo"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="작업구분" :value="param.workClassName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="작업유형" :value="param.workTypeName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="작업형태" :value="param.workKindName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="위험등급" :value="param.hazardGradeName"></c-label-text>
          </div>
          <div class="col-1">
            <c-label-text title="제(개)정번호" :value="param.revisionNum"></c-label-text>
          </div>
          <div class="col-1">
            <c-label-text title="제(개)정자" :value="param.regUserName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="제(개)정일시" :value="param.regDtStr"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="작업절차"
        tableId="steptable"
        topBorderClass="topcolor-lightblue"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :isExcelDown="false"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sop-report',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        plantName: '',
        sopName: '',
        sopNo: '',
        processName: '',
        hazardGradeName: '',
        workTypeName: '',
        workKindName: '',
        workClassName: '',
        revisionNum: '',
        regDtStr: '',
        regUserName: '',
        isRevPop: false,
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'jobStepName' },
          { index: 1, colName: 'jobStepName' },
          { index: 2, colName: 'jobStepName' },
          { index: 6, colName: 'jobStepName' },
          { index: 7, colName: 'jobStepName' },
          { index: 8, colName: 'jobStepName' },
        ],
        columns: [
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업단계',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'downPath',
            field: 'downPath',
            label: '도해(사진/그림)',
            align: 'center',
            style: 'width:150px',
            type: 'img',
            sortable: false,
          },
          {
            name: 'jobStepDesc',
            field: 'jobStepDesc',
            label: '작업방법(상세설명)',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '잠재위험성',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '원인',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'caution',
            field: 'caution',
            label: '안전작업방법(주의사항)',
            align: 'center',
            type: 'html',
            sortable: false,
          },
          {
            name: 'saftyShied',
            field: 'saftyShied',
            label: '안전보호구',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'useTool',
            field: 'useTool',
            label: '사용공구',
            align: 'left',
            type: 'html',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      detailUrl: '',
      listlUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'param.updateFlag' () {
      this.getSopDetail();
      this.getDetail();
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.param.isRevPop) {
        this.editable = false;
      }
      // url setting
      this.detailUrl = selectConfig.mdm.sop.job.get.url;
      this.listlUrl = selectConfig.mdm.sop.jobhazardreason.url;
      this.getSopDetail();
      this.getDetail();
    },
    getSopDetail() {
      if (this.param.mdmSopId) {
        this.$http.url = this.$format(this.detailUrl, this.param.mdmSopId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.param.plantName = _result.data.plantName;
          this.param.sopName = _result.data.sopName;
          this.param.sopNo = _result.data.sopNo;
          this.param.processName = _result.data.processName;
          this.param.hazardGradeName = _result.data.hazardGradeName;
          this.param.workTypeName = _result.data.workTypeName;
          this.param.workKindName = _result.data.workKindName;
          this.param.workClassName = _result.data.workClassName;
        },);
      }
    },
    getDetail() {
      if (this.param.mdmSopId) {
        this.$http.url = this.listlUrl;
        this.$http.type = 'GET';
        this.$http.param = {mdmSopId: this.param.mdmSopId};
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    }
  }
};
</script>